













































































































































































































































































































































































































































































import Uppy from '@uppy/core';
import FileInput from '@uppy/file-input';
import { inject } from 'inversify-props';
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import { random } from 'lodash';
import { Dashboard } from '@uppy/vue';
import { DashboardOptions } from '@uppy/dashboard';
import SessionService from '@/services/session.service';
import MetasService from '@/services/cr/metas.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import dayjs from '@/plugins/dayjs';
import MetasClienteModel from '@/models/cr/metas-cliente.model';
import MetasRepresUploadModel from '@/models/cr/metas-repres-upload.model';
import MetasClienteUploadModel from '@/models/cr/metas-cliente-upload.model';
import MetasLinhaUploadModel from '@/models/cr/metas-linha-upload.model';

@Component({
  components: {
    Dashboard,
  },
})
export default class MetasClienteUpload extends Vue {
  @inject(InjectionIdEnum.SessionService)
  protected sessionService!: SessionService;

  @inject(InjectionIdEnum.CrMetasService)
  private metasService!: MetasService;

  uppyInstance1: Uppy | null = null;

  uppyInstance2: Uppy | null = null;

  uppyInstance3: Uppy | null = null;

  showUploader1 = false;

  showUploader2 = false;

  showUploader3 = false;

  checkRepres = false;

  checkCli = false;

  checkProd = false;

  btnCancelar = false;

  btnFinalizar = false;

  panel1 = true;

  panel2 = false;

  btnEnabled = 1;

  planilha1 = '';

  planilha2 = '';

  planilha3 = '';

  posCodRepres = 1;

  posCodRepresCli = 1;

  posCodRepresLinha = 1;

  posCodCli = 2;

  posCodCliLinha = 2;

  posCodLinha = 3;

  posMetaQuantidadeRepres = 2;

  posMetaValorRepres = 3;

  posMetaQuantidadeCli = 3;

  posMetaValorCli = 4;

  posMetaQuantidadeLinha = 4;

  posMetaValorLinha = 5;

  colRepres = ',';

  colCli = ',';

  colProd = ',';

  textRepres = '"';

  textCli = '"';

  textProd = '"';

  sepRepres = '.';

  sepCli = '.';

  sepProd = '.';

  initRepres = 1;

  initCli = 1;

  initProd = 1;

  erroRepres = false;

  erroCli = false;

  erroLinha = false;

  nivelArray = [1, 2, 3];

  etapa1 = 1;

  etapa2 = 2;

  etapa3 = 3;

  etapa4 = 4;

  representante = 1;

  cliente = 2;

  linha = 3;

  headers1 = [
    {
      text: 'Repres.',
      align: 'center',
      sortable: false,
      value: 'posCodRepres',
      class: 'header-class white--text',
    },
    {
      text: 'Meta em quantidade',
      align: 'end',
      sortable: false,
      value: 'metaQuantidade',
      class: 'header-class white--text',
    },
    {
      text: 'Meta em valor',
      align: 'end',
      sortable: false,
      value: 'metaValor',
      class: 'header-class white--text',
    },
  ];

  headers2 = [
    {
      text: 'Repres.',
      align: 'center',
      sortable: false,
      value: 'posCodRepresCli',
      class: 'header-class white--text',
    },
    {
      text: 'Cliente',
      align: 'center',
      sortable: false,
      value: 'posCodCli',
      class: 'header-class white--text',
    },
    {
      text: 'Meta em qtde',
      align: 'end',
      sortable: false,
      value: 'metaQuantidade',
      class: 'header-class white--text',
    },
    {
      text: 'Meta em valor',
      align: 'end',
      sortable: false,
      value: 'metaValor',
      class: 'header-class white--text',
    },
  ];

  headers3 = [
    {
      text: 'Repres.',
      align: 'center',
      sortable: false,
      value: 'posCodRepresLinha',
      class: 'header-class white--text',
    },
    {
      text: 'Cliente',
      align: 'center',
      sortable: false,
      value: 'posCodCliLinha',
      class: 'header-class white--text',
    },
    {
      text: 'Linha',
      align: 'center',
      sortable: false,
      value: 'posCodLinha',
      class: 'header-class white--text',
    },
    {
      text: 'Meta em qtde',
      align: 'end',
      sortable: false,
      value: 'metaQuantidade',
      class: 'header-class white--text',
    },
    {
      text: 'Meta em valor',
      align: 'end',
      sortable: false,
      value: 'metaValor',
      class: 'header-class white--text',
    },
  ];

  metasRepres = Array<MetasRepresUploadModel>();

  metasCli = Array<MetasClienteUploadModel>();

  metasProd = Array<MetasLinhaUploadModel>();

  selected = Array<MetasClienteModel>();

  gerarMetasRepres = false;

  gerarMetasCli = false;

  clickNext() {
    if (!this.checkRepres && !this.checkCli && !this.checkProd) {
      this.$notify.error('Habilite ao menos um elemento e adicione uma planilha ao mesmo!');
      return;
    }
    if (this.btnEnabled === this.etapa1 && ((this.checkRepres && this.planilha1.trim() === '')
    || (this.checkCli && this.planilha2.trim() === '') || (this.checkProd && this.planilha3.trim() === ''))) {
      this.$notify.error('Desabilite elementos habilitados sem planilha anexada ou adicione uma planilha aos mesmos!');
      return;
    }
    if (this.btnEnabled === this.etapa2 && ((this.planilha1 !== '' && this.metasRepres.length === 0) || (this.planilha2
      !== '' && this.metasCli.length === 0) || (this.planilha3 !== '' && this.metasProd.length === 0))) {
      this.$notify.error('Certifique-se de que as configurações de todos os elementos anexados estão corretas!');
      return;
    }
    if (this.btnEnabled === this.etapa1) {
      this.getItems(this.representante);
      this.getItems(this.cliente);
      this.getItems(this.linha);
    }
    if (this.btnEnabled === this.etapa2) {
      this.reviewData();
      if (this.erroRepres || this.erroCli || this.erroLinha) {
        this.$notify.error('Certifique-se de que as configurações de todos os elementos anexados estão corretas!');
        return;
      }
    }
    if (this.btnEnabled === this.etapa3) {
      this.panel1 = !this.panel1;
      this.panel2 = !this.panel2;
    }
    this.btnEnabled += 1;
  }

  clickPrevious() {
    if (this.btnEnabled === this.etapa4) {
      this.panel1 = !this.panel1;
      this.panel2 = !this.panel2;
    }
    this.btnEnabled -= 1;
  }

  getItems(nivel: number) {
    if (nivel === this.representante && this.planilha1 !== '') {
      const auxs1 = this.planilha1.split('\r\n');
      this.metasRepres = Array<MetasRepresUploadModel>();
      for (let i = 0; i < auxs1.length; i += 1) {
        if (i + 1 >= this.initRepres) {
          if (auxs1[i].split(this.colRepres) && auxs1[i].split(this.colRepres).length >= 3) {
            const aux = auxs1[i].split(this.colRepres);
            this.metasRepres.push(new MetasRepresUploadModel(aux[this.posCodRepres - 1].replaceAll(this.textRepres, ''),
              aux[this.posMetaQuantidadeRepres - 1].replaceAll(this.sepRepres, '.'),
              aux[this.posMetaValorRepres - 1].replaceAll(this.sepRepres, '.')));
          }
        }
      }
    } else if (nivel === this.cliente && this.planilha2 !== '') {
      const auxs1 = this.planilha2.split('\r\n');
      this.metasCli = Array<MetasClienteUploadModel>();
      for (let i = 0; i < auxs1.length; i += 1) {
        if (i + 1 >= this.initCli) {
          if (auxs1[i].split(this.colCli) && auxs1[i].split(this.colCli).length >= 3) {
            const aux = auxs1[i].split(this.colCli);
            this.metasCli.push(new MetasClienteUploadModel(aux[this.posCodRepresCli - 1]
              .replaceAll(this.textCli, ''),
            aux[this.posCodCli - 1].replaceAll(this.textCli, ''),
            aux[this.posMetaQuantidadeCli - 1].replaceAll(this.sepCli, '.'),
            aux[this.posMetaValorCli - 1].replaceAll(this.sepCli, '.')));
          }
        }
      }
    } else if (nivel === this.linha && this.planilha3 !== '') {
      const auxs1 = this.planilha3.split('\r\n');
      this.metasProd = Array<MetasLinhaUploadModel>();
      for (let i = 0; i < auxs1.length; i += 1) {
        if (i + 1 >= this.initProd) {
          if (auxs1[i].split(this.colProd) && auxs1[i].split(this.colProd).length >= 3) {
            const aux = auxs1[i].split(this.colProd);
            this.metasProd.push(new MetasLinhaUploadModel(aux[this.posCodRepresLinha - 1]
              .replaceAll(this.textProd, ''),
            aux[this.posCodCliLinha - 1].replaceAll(this.textProd, ''),
            aux[this.posCodLinha - 1].replaceAll(this.textProd, ''),
            aux[this.posMetaQuantidadeLinha - 1].replaceAll(this.sepProd, '.'),
            aux[this.posMetaValorLinha - 1].replaceAll(this.sepProd, '.')));
          }
        }
      }
    }
  }

  changeCheck() {
    if (!this.checkRepres && this.planilha1 !== '') {
      this.planilha1 = '';
    } else if (!this.checkCli && this.planilha2 !== '') {
      this.planilha2 = '';
    } else if (!this.checkProd && this.planilha3 !== '') {
      this.planilha3 = '';
    }
  }

  setValueCodRepres(event: Event, nivel: number) {
    if (nivel === this.representante && event) {
      this.posCodRepres = Number(event.toString().trim());
      this.getItems(nivel);
      return;
    }
    if (nivel === this.cliente) {
      this.posCodRepresCli = Number(event.toString().trim());
      this.getItems(nivel);
      return;
    }
    this.posCodRepresLinha = Number(event.toString().trim());
    this.getItems(nivel);
  }

  getValueCodRepres(nivel: number) {
    if (nivel === this.representante) {
      return this.posCodRepres;
    }
    if (nivel === this.cliente) {
      return this.posCodRepresCli;
    }
    return this.posCodRepresLinha;
  }

  setValueCodCli(event: Event, nivel: number) {
    if (nivel === this.cliente) {
      this.posCodCli = Number(event.toString().trim());
      this.getItems(nivel);
      return;
    }
    this.posCodCliLinha = Number(event.toString().trim());
    this.getItems(nivel);
  }

  getValueCodCli(nivel: number) {
    if (nivel === this.cliente) {
      return this.posCodCli;
    }
    return this.posCodCliLinha;
  }

  setValueCodLinha(event: Event) {
    this.posCodLinha = Number(event.toString().trim());
    this.getItems(this.linha);
  }

  getValueCodLinha() {
    return this.posCodLinha;
  }

  setValueMetaQtde(event: Event, nivel: number) {
    if (nivel === this.representante && event) {
      this.posMetaQuantidadeRepres = Number(event.toString().trim());
      this.getItems(nivel);
      return;
    }
    if (nivel === this.cliente) {
      this.posMetaQuantidadeCli = Number(event.toString().trim());
      this.getItems(nivel);
      return;
    }
    this.posMetaQuantidadeLinha = Number(event.toString().trim());
    this.getItems(nivel);
  }

  getValueMetaQtde(nivel: number) {
    if (nivel === this.representante) {
      return this.posMetaQuantidadeRepres;
    }
    if (nivel === this.cliente) {
      return this.posMetaQuantidadeCli;
    }
    return this.posMetaQuantidadeLinha;
  }

  setValueMetaValor(event: Event, nivel: number) {
    if (nivel === this.representante && event) {
      this.posMetaValorRepres = Number(event.toString().trim());
      this.getItems(nivel);
      return;
    }
    if (nivel === this.cliente) {
      this.posMetaValorCli = Number(event.toString().trim());
      this.getItems(nivel);
      return;
    }
    this.posMetaValorLinha = Number(event.toString().trim());
    this.getItems(nivel);
  }

  getValueMetaValor(nivel: number) {
    if (nivel === this.representante) {
      return this.posMetaValorRepres;
    }
    if (nivel === this.cliente) {
      return this.posMetaValorCli;
    }
    return this.posMetaValorLinha;
  }

  setValueCol(event: Event, nivel: number) {
    if (nivel === this.representante && event) {
      this.colRepres = event.toString().trim();
      this.getItems(nivel);
      return;
    }
    if (nivel === this.cliente) {
      this.colCli = event.toString().trim();
      this.getItems(nivel);
      return;
    }
    this.colProd = event.toString().trim();
    this.getItems(nivel);
  }

  getValueCol(nivel: number) {
    if (nivel === this.representante) {
      return this.colRepres;
    }
    if (nivel === this.cliente) {
      return this.colCli;
    }
    return this.colProd;
  }

  setValueText(event: Event, nivel: number) {
    if (nivel === this.representante && event) {
      this.textRepres = event.toString().trim();
      this.getItems(nivel);
      return;
    }
    if (nivel === this.cliente) {
      this.textCli = event.toString().trim();
      this.getItems(nivel);
      return;
    }
    this.textProd = event.toString().trim();
    this.getItems(nivel);
  }

  getValueText(nivel: number) {
    if (nivel === this.representante) {
      return this.textRepres;
    }
    if (nivel === this.cliente) {
      return this.textCli;
    }
    return this.textProd;
  }

  setValueSep(event: Event, nivel: number) {
    if (nivel === this.representante && event) {
      this.sepRepres = event.toString().trim();
      this.getItems(nivel);
      return;
    }
    if (nivel === this.cliente) {
      this.sepCli = event.toString().trim();
      this.getItems(nivel);
      return;
    }
    this.sepProd = event.toString().trim();
    this.getItems(nivel);
  }

  getValueSep(nivel: number) {
    if (nivel === this.representante) {
      return this.sepRepres;
    }
    if (nivel === this.cliente) {
      return this.sepCli;
    }
    return this.sepProd;
  }

  setValueInit(event: Event, nivel: number) {
    if (nivel === this.representante && event) {
      this.initRepres = Number(event.toString().trim());
      this.getItems(nivel);
      return;
    }
    if (nivel === this.cliente) {
      this.initCli = Number(event.toString().trim());
      this.getItems(nivel);
      return;
    }
    this.initProd = Number(event.toString().trim());
    this.getItems(nivel);
  }

  getValueInit(nivel: number) {
    if (nivel === this.representante) {
      return this.initRepres;
    }
    if (nivel === this.cliente) {
      return this.initCli;
    }
    return this.initProd;
  }

  reviewData() {
    this.erroRepres = false;
    this.erroCli = false;
    this.erroLinha = false;
    if (this.metasRepres.length > 0 && this.metasRepres.find((obj) => !obj.codRepres
    || (!obj.metaQuantidade && obj.metaQuantidade !== '0')
    || (obj.metaQuantidade && !MetasClienteUpload.isNumber(obj.metaQuantidade.toString()))
    || (!obj.metaValor && obj.metaValor !== '0')
    || (obj.metaValor && !MetasClienteUpload.isNumber(obj.metaValor)))) {
      this.erroRepres = true;
    }
    if (this.metasCli.length > 0 && this.metasCli.find((obj) => !obj.codRepres || !obj.codCli
    || (!obj.metaQuantidade && obj.metaQuantidade !== '0')
    || (obj.metaQuantidade && !MetasClienteUpload.isNumber(obj.metaQuantidade.toString()))
    || (!obj.metaValor && obj.metaValor !== '0')
    || (obj.metaValor && !MetasClienteUpload.isNumber(obj.metaValor)))) {
      this.erroCli = true;
    }
    if (this.metasProd.length > 0 && this.metasProd.find((obj) => !obj.codRepres || !obj.codCli || !obj.codLinha
    || (!obj.metaQuantidade && obj.metaQuantidade !== '0')
    || (obj.metaQuantidade && !MetasClienteUpload.isNumber(obj.metaQuantidade.toString()))
    || (!obj.metaValor && obj.metaValor !== '0')
    || (obj.metaValor && !MetasClienteUpload.isNumber(obj.metaValor)))) {
      this.erroLinha = true;
    }
  }

  @VModel()
  dialog!: boolean;

  @Prop()
  enableDescription!: boolean;

  @Prop()
  place!: string;

  @Prop()
  hash!: string;

  @Prop()
  title!: string;

  created() {
    this.$vuetify.theme.currentTheme.primary = '#74c600';
    if (this.dialog) {
      if (!this.uppyInstance1) {
        this.uppyInstance1 = this.getUppyInstance();
        this.uppyInstance1.use(FileInput, {
          pretty: true,
          inputName: 'files[]',
        });
      }
      if (!this.uppyInstance2) {
        this.uppyInstance2 = this.getUppyInstance();
        this.uppyInstance2.use(FileInput, {
          pretty: true,
          inputName: 'files[]',
        });
      }
      if (!this.uppyInstance3) {
        this.uppyInstance3 = this.getUppyInstance();
        this.uppyInstance3.use(FileInput, {
          pretty: true,
          inputName: 'files[]',
        });
      }
      this.uppyInstance1.setMeta({
        usuarioId: 1,
        place: this.place || '',
        hash: this.hash || '',
      });
      this.showUploader1 = true;
      this.uppyInstance2.setMeta({
        usuarioId: 1,
        place: this.place || '',
        hash: this.hash || '',
      });
      this.showUploader2 = true;
      this.uppyInstance3.setMeta({
        usuarioId: 1,
        place: this.place || '',
        hash: this.hash || '',
      });
      this.showUploader3 = true;
    } else {
      setTimeout(() => {
        this.showUploader1 = false;
        this.showUploader2 = false;
        this.showUploader3 = false;
      }, 450);
    }
  }

  afterClose(): void {
    this.$emit('after-close');
  }

  onClose(): void {
    this.btnEnabled = this.etapa1;
    this.dialog = false;
  }

  onCancel(): void {
    this.uppyInstance1?.cancelAll();
    this.uppyInstance2?.cancelAll();
    this.uppyInstance3?.cancelAll();
    this.btnEnabled = this.etapa1;
    this.btnFinalizar = false;
    this.btnCancelar = false;
    this.checkRepres = false;
    this.checkCli = false;
    this.checkProd = false;
    this.dialog = false;
  }

  beforeDestroy(): void {
    if (this.uppyInstance1) {
      this.uppyInstance1.close();
      this.uppyInstance1 = null;
      this.metasRepres = Array<MetasRepresUploadModel>();
    }
    if (this.uppyInstance2) {
      this.uppyInstance2.close();
      this.uppyInstance2 = null;
      this.metasCli = Array<MetasClienteUploadModel>();
    }
    if (this.uppyInstance3) {
      this.uppyInstance3.close();
      this.uppyInstance3 = null;
      this.metasProd = Array<MetasLinhaUploadModel>();
    }
  }

  get dashboardProps1(): DashboardOptions {
    return {
      inline: true,
      width: '100%',
      height: 'calc(50vh - 150px)',
      proudlyDisplayPoweredByUppy: false,
      metaFields: this.enableDescription ? [{ id: 'description', name: 'Descrição', placeholder: '' }] : [],
      hideUploadButton: true,
      hideRetryButton: true,
      disabled: !this.checkRepres,
    };
  }

  get dashboardProps2(): DashboardOptions {
    return {
      inline: true,
      width: '100%',
      height: 'calc(50vh - 150px)',
      proudlyDisplayPoweredByUppy: false,
      metaFields: this.enableDescription ? [{ id: 'description', name: 'Descrição', placeholder: '' }] : [],
      hideUploadButton: true,
      hideRetryButton: true,
      disabled: !this.checkCli,
    };
  }

  get dashboardProps3(): DashboardOptions {
    return {
      inline: true,
      width: '100%',
      height: 'calc(50vh - 150px)',
      proudlyDisplayPoweredByUppy: false,
      metaFields: this.enableDescription ? [{ id: 'description', name: 'Descrição', placeholder: '' }] : [],
      hideUploadButton: true,
      hideRetryButton: true,
      disabled: !this.checkProd,
    };
  }

  get dialogTitle(): string {
    return this.title || `${this.$t('global.metasClienteUpload.title')}`;
  }

  private getUppyInstance(): Uppy {
    const uppy = new Uppy({
      locale: {
        strings: {
          dropHint: 'Solte seu arquivo aqui',
          dropPasteFiles: 'Solte seu arquivo aqui ou %{browseFiles}',
          browseFiles: 'Selecione...',
          dropPasteImportFiles: '',
          cancel: 'Cancelar',
          removeFile: 'Remover arquivo',
          xFilesSelected: {
            0: '%{smart_count} arquivo selecionado',
            1: '%{smart_count} arquivos selecionados',
          },
        },
      },
      id: `${dayjs().format()}${random(1, 15)}`,
      autoProceed: false,
      restrictions: {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['text/csv'],
      },
    })
      .on('file-removed', (currentFile) => {
        if (this.uppyInstance1 && this.uppyInstance1.getFiles()[0] === undefined) {
          this.metasRepres = Array<MetasRepresUploadModel>();
          this.planilha1 = '';
          uppy.removeFile(currentFile.id);
        }
        if (this.uppyInstance2 && this.uppyInstance2.getFiles()[0] === undefined) {
          this.metasCli = Array<MetasClienteUploadModel>();
          this.planilha2 = '';
          uppy.removeFile(currentFile.id);
        }
        if (this.uppyInstance3 && this.uppyInstance3.getFiles()[0] === undefined) {
          this.metasProd = Array<MetasLinhaUploadModel>();
          this.planilha3 = '';
          uppy.removeFile(currentFile.id);
        }
        return false;
      })
      .on('file-added', (currentFile) => {
        const reader = new FileReader();
        reader.readAsText(currentFile.data);
        reader.onload = () => {
          if (reader.result) {
            if (this.uppyInstance1 && this.uppyInstance1.getFiles()[0] === currentFile) {
              this.planilha1 = reader.result.toString();
              return false;
            }
            if (this.uppyInstance2 && this.uppyInstance2.getFiles()[0] === currentFile) {
              this.planilha2 = reader.result.toString();
              return false;
            }
            if (this.uppyInstance3) {
              this.planilha3 = reader.result.toString();
              return false;
            }
          }
          return true;
        };
        return true;
      });
    return uppy;
  }

  static parseSize(size: number): string {
    let parsedSize = '';
    const mbFactor = 1048576;
    const kbFactor = 1024;

    if (size >= mbFactor) {
      parsedSize = `${Math.round(size / mbFactor)}MB`;
    } else if (size >= kbFactor) {
      parsedSize = `${Math.round(size / kbFactor)}KB`;
    } else {
      parsedSize = `${size}bytes`;
    }

    return parsedSize;
  }

  static isNumber(str: string): boolean {
    if (typeof str !== 'string') {
      return false;
    }
    if (str.trim() === '') {
      return false;
    }
    return !Number.isNaN(Number(str));
  }

  setMetasClientes() {
    this.btnFinalizar = true;
    this.btnCancelar = true;
    this.metasRepres.forEach((obj) => {
      const aux = new MetasClienteModel([''], '', 0n, this.representante, '', obj.codRepres, '', '', '', 0, 1,
        0, 1, Number(obj.metaQuantidade), Number(parseFloat(obj.metaValor).toFixed(2)));
      this.selected.push(aux);
    });
    this.metasCli.forEach((obj) => {
      const aux = new MetasClienteModel([''], '', 0n, this.cliente, '', obj.codRepres, obj.codCli, '', '', 0, 1,
        0, 1, Number(obj.metaQuantidade), Number(parseFloat(obj.metaValor).toFixed(2)));
      this.selected.push(aux);
    });
    this.metasProd.forEach((obj) => {
      const aux = new MetasClienteModel([''], '', 0n, this.linha, '', obj.codRepres, obj.codCli,
        obj.codLinha, '', 0, 1, 0, 1, Number(obj.metaQuantidade), Number(parseFloat(obj.metaValor).toFixed(2)));
      this.selected.push(aux);
    });
    this.save();
  }

  private async save() {
    try {
      const params = {
        selected: this.selected,
        idContrato: this.$route.query.idContrato,
      };
      await this.metasService.saveConfigs(
        params.selected, params.idContrato.toString(),
        this.gerarMetasRepres,
        this.gerarMetasCli,
      );
      if (this.selected.filter((meta) => meta.nivel === 3).length > 0) {
        const selectedLinhas = this.selected.filter((meta) => meta.nivel === 3);
        await this.saveLinhas(selectedLinhas);
      }

      this.selected = [];
      this.gerarMetasRepres = false;
      this.gerarMetasCli = false;
      this.$notify.success('Dados salvos com sucesso!');
      this.onCancel();
    } catch (err) {
      console.log(err);
      this.$notify.error(err && (err as Error).message);
    }
  }

  private async saveLinhas(selectedLinhas: MetasClienteModel[]) {
    try {
      const execucoes = Math.ceil(Number(selectedLinhas.length / 10000));
      let begin = 0;
      let end = 10000;
      for (let execucao = 0; execucao <= execucoes; execucao += 1) {
        const selectedLinhaChunked = selectedLinhas.slice(begin, end > selectedLinhas.length
          ? selectedLinhas.length : end);
        const paramsLinha = {
          selected: selectedLinhaChunked,
          idContrato: this.$route.query.idContrato,
        };
        this.serviceSaveLinhas(paramsLinha);
        begin += 10000;
        end += 10000;
      }
    } catch (err) {
      console.log(err);
      this.$notify.error(err && (err as Error).message);
    }
  }

  private async serviceSaveLinhas(paramsLinha) {
    await this.metasService.saveConfigsLinha(
      paramsLinha.selected, paramsLinha.idContrato.toString(),
    );
  }
}
