

































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { GridOptions, RowNode } from '@ag-grid-community/core';
import { NumberHelper } from '@/utils/helpers/number-helper';
import MetasService from '@/services/cr/metas.service';
import MetasPeriodosService from '@/services/cr/metas-periodos.service';
import MetasUploadService from '@/services/cr/metas-upload.service';
import AttachmentService from '@/services/crm/attachment.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { AttachmentTypeEnum } from '@/enums/crm/attachment-type.enum';
import MetasClienteModel from '@/models/cr/metas-cliente.model';
import AgGridLoading from '@/components/ag-grid-loading.vue';
import MetasClienteUpload from '@/components/metas-cliente-upload.vue';

@Component({
  components: {
    AgGridVue,
    AgGridLoading,
    MetasClienteUpload,
  },
})
export default class MetasCliente extends Vue {
  @Watch('showUploader')
  onValueChange(value: boolean): void {
    if (!value) this.afterCloseDialog();
  }

  @inject(InjectionIdEnum.CrMetasPeriodosService)
  metasPeriodosService!: MetasPeriodosService;

  @inject(InjectionIdEnum.CrMetasService)
  private metasService!: MetasService;

  @inject(InjectionIdEnum.CrMetasUploadService)
  protected uploadService!: MetasUploadService;

  @inject(InjectionIdEnum.CrmAttachmentService)
  protected attachmentService!: AttachmentService;

  niveisHeader = [{ id: 1, nome: 'Representante' }, { id: 2, nome: 'Cliente' }];

  private nivelHeader = this.niveisHeader[0];

  private filterObjectRepres;

  private filterObjectCli;

  private tiposMetaCalc: Array<Array<string>> = Array(Array<string>());

  private metasPeriodos;

  private items;

  private searchResults = Array<MetasClienteModel>();

  private searchResultsChildren = Array<MetasClienteModel>();

  private searchResultsChildrenDistinct = Array<MetasClienteModel>();

  private isLoading = true;

  private loadingChildren;

  private isSelecting = false;

  private selectedFile = {};

  private showUploader = false;

  private uploadEndpoint = '';

  private attachmentType: AttachmentTypeEnum = AttachmentTypeEnum.Metas;

  private focus = 0;

  private filterRepCadAtivo = false;

  private filterRepConAtivo = false;

  private filterCliCadAtivo = false;

  private filterCliConAtivo = false;

  private filterProdCadAtivo = false;

  private filterProdConAtivo = false;

  private totalQtde;

  private totalVal;

  private selected: MetasClienteModel[] = Array<MetasClienteModel>();

  private gridApi;

  private gridOptions: GridOptions = {};

  private autoGroupColumnDef = {};

  private defaultColDef = {};

  private readonly debounce = 450;

  private debounceId!: number;

  searching = false;

  currentNode!: RowNode;

  representante!: number;

  cliente!: number;

  linha!: number;

  async created(): Promise<void> {
    this.representante = 1;
    this.cliente = 2;
    this.linha = 3;
    this.$vuetify.theme.currentTheme.primary = '#74c600';
    this.uploadEndpoint = this.uploadService.getUploadUrl();
    this.autoGroupColumnDef = { headerName: 'REPRESENTANTE/CLIENTE/LINHA DE PRODUTO',
      minWidth: 400,
      cellRendererParams: { suppressCount: true,
        suppressDoubleClickExpand: false,
        innerRenderer: (param) => {
          let res = '';
          if (param && param.data) {
            let rowText = '';
            if (param.data.nivel === this.representante) {
              rowText = `${param.data.codRepres} - ${param.data.nome}`;
            } else if (param.data.nivel === this.cliente) {
              rowText = `${param.data.codCli} - ${param.data.nome}`;
            } else {
              rowText = `${param.data.codLinhaProd} - ${param.data.nome}`;
            }
            let text = param.data.nivel === this.representante ? 'Representante' : 'Cliente';
            text = param.data.nivel === this.linha ? 'Linha de Produto' : text;
            res += '<div style="display: inline-flex">';
            if (param.data.nivel !== this.linha) {
              res += `<button style="" title="Clique para carregar os dados de ${text}">
              <svg style="width: 18px; height:40px; vertical-align: middle;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"/></svg>
              </button>`;
            }
            res += `<ul style="height: 40px; list-style-type: none; padding-left: 10px;
              line-height: ${param.data.nivel !== this.linha ? '1.8' : '1.5'};">
              ${rowText}<li style="list-style-type: none; font-size: 10px;
              line-height: ${param.data.nivel !== this.linha ? '0.5' : '0.3'}">${text}</li></ul></div>`;
          }
          return res;
        } } };
    this.gridOptions = {
      headerHeight: 25,
      groupHeaderHeight: 25,
      rowHeight: 40,
      groupRowRenderer: 'agGroupCellRenderer',
      suppressHorizontalScroll: false,
      singleClickEdit: true,
      stopEditingWhenCellsLoseFocus: true,
      onCellClicked: (event) => {
        if (!event.node?.rowPinned && event.colDef && event.colDef.colId && event.colDef.colId.toString()
        === 'ag-Grid-AutoColumn' && event.node.allChildrenCount === null) {
          this.onRowClicked(event);
          this.currentNode = event.node;
        }
      },
      onCellValueChanged: (event) => {
        if (!event.node?.rowPinned && event.colDef && event.colDef.field && (event.colDef.field === 'metaQuantidade'
          || event.colDef.field === 'metaValor' || event.colDef.field === 'tipoCalcMeta')) {
          if (event.colDef.field === 'tipoCalcMeta' && event.newValue.toString() === '1') {
            this.downSum(event);
          } else {
            this.upSum(event);
          }
          if (this.selected.filter((obj) => obj.orgHierarchyString === event.data.orgHierarchyString)) {
            this.selected = this.selected.filter((obj) => obj.orgHierarchyString !== event.data.orgHierarchyString);
          }
          this.selected.push(event.data);
          if (event.node.parent?.data) {
            this.selected = this.selected.filter((obj) => obj.orgHierarchyString
            !== event.node.parent?.data.orgHierarchyString);
            this.selected.push(event.node.parent.data);
          }
          if (event.node.parent && event.node.parent.parent?.data) {
            this.selected = this.selected.filter((obj) => obj.orgHierarchyString
            !== event.node.parent?.parent?.data.orgHierarchyString);
            this.selected.push(event.node.parent?.parent.data);
          }
        }
        this.setFocus();
        this.gridApi.setPinnedBottomRowData(this.bottomRowData());
      },
      onRowDataChanged: () => {
        if (this.loadingChildren) {
          this.filterDataTree();
          this.loadingChildren = false;
          this.setFocus();
        }
        if (!this.isLoading) {
          this.gridApi.setPinnedBottomRowData(this.bottomRowData());
          this.setFocus();
        }
      },
    };
    this.defaultColDef = {
      editable: false,
      flex: 1,
    };
  }

  async getMetasPeriodos(): Promise<void> {
    try {
      const result = await this.metasPeriodosService.getMetasPeriodos();
      this.metasPeriodos = JSON.parse(JSON.stringify(Object.fromEntries(result)));
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  async getClients(e: Event) {
    clearTimeout(this.debounceId);
    this.searching = true;
    this.debounceId = setTimeout(async () => {
      try {
        if (e && e !== undefined) {
          const params = {
            idContrato: this.$route.query.idContrato,
            codNome: e.toString(),
          };
          const result = await this.metasService.getClients(params.idContrato.toString(), params.codNome);
          this.searchResultsChildren = result;
          this.searchResultsChildren.forEach((obj1) => {
            if (this.searchResultsChildrenDistinct.length === 0
            || !this.searchResultsChildrenDistinct.find((obj2) => obj1.codCli === obj2.codCli)) {
              this.searchResultsChildrenDistinct.push(obj1);
            }
          });
        }
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.searching = false;
      }
    }, this.debounce);
  }

  async getConfigs() {
    clearTimeout(this.debounceId);
    this.searching = true;
    this.debounceId = setTimeout(async () => {
      try {
        const params = {
          idContrato: this.$route.query.idContrato,
        };
        const result = await this.metasService.getConfigs(params.idContrato.toString());
        this.items = result;
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.searching = false;
      }
    }, this.debounce);
  }

  private async getChildren(nivel: number, idConfig: string, codRepres: string, codCli: string) {
    clearTimeout(this.debounceId);
    this.searching = true;
    this.debounceId = setTimeout(async () => {
      try {
        const params = {
          idConfigRepres: idConfig,
          idContrato: this.$route.query.idContrato,
          codRepresentante: codRepres,
          codCliente: codCli,
        };
        let result = Array<MetasClienteModel>();
        if (nivel === this.representante) {
          result = await this.metasService.getConfigsChildren(params.idConfigRepres,
            params.idContrato.toString(), params.codRepresentante);
        } else if (nivel === this.cliente) {
          result = await this.metasService.getConfigsGrandChildren(params.idConfigRepres,
            params.idContrato.toString(), params.codRepresentante, params.codCliente);
        }
        if (result && result.length > 0) {
          result.forEach((obj) => {
            this.items.push(obj);
          });
        } else {
          this.$notify.warn('Representante sem clientes na carteira!');
        }
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.searching = false;
      }
    }, this.debounce);
    this.loadingChildren = true;
  }

  setFocus() {
    if (this.gridApi) {
      this.gridApi.forEachNode((node) => {
        if (node && node.data && this.currentNode && node.data.orgHierarchyString
          === this.currentNode.data.orgHierarchyString) {
          this.focus = node.rowIndex;
          node.setExpanded(true);
          if (node.parent.level !== -1) {
            node.parent.setExpanded(true);
          }
        } else {
          node.setExpanded(false);
        }
      });
      this.gridApi.ensureIndexVisible(this.focus, 'top');
    }
  }

  async afterCloseDialog() {
    this.gridApi.setRowData([]);
    this.getConfigs();
  }

  isPercPeriodo() {
    if (this.$route.query && this.$route.query.percPeriodo.toString()) {
      const percPeriodo = this.$route.query.percPeriodo.toString();
      if (percPeriodo === '1') {
        return false;
      }
      return true;
    }
    return true;
  }

  bottomRowData(): MetasClienteModel[] {
    const ret = new Array<MetasClienteModel>();
    let totalQtde = 0;
    let totalVal = 0;
    if (this.gridOptions.rowData) {
      this.gridOptions.rowData.forEach((obj) => {
        if (obj.nivel === this.representante) {
          totalQtde += Number(obj.metaQuantidade);
          totalVal += Number(obj.metaValor);
        }
      });
      ret.push(new MetasClienteModel(['Total'], 'Total', -1n, this.representante, 'Total', '', '', '', '', 0, 0, 0, 0,
        totalQtde, totalVal));
    }
    return ret;
  }

  downSum(event) {
    let totalQtde = 0;
    let totalVal = 0;

    event.node.childrenAfterFilter.forEach((obj) => {
      totalQtde += Number(obj.data.metaQuantidade);
      totalVal += Number(obj.data.metaValor);
    });
    if (this.gridOptions.rowData && this.gridOptions.rowData.find((obj) => event.data.orgHierarchyString
      === obj.orgHierarchyString)) {
      this.gridOptions.rowData.find((obj) => event.data.orgHierarchyString
      === obj.orgHierarchyString).metaQuantidade = totalQtde;
      this.gridOptions.rowData.find((obj) => event.data.orgHierarchyString
      === obj.orgHierarchyString).metaValor = totalVal;
      this.gridApi.setRowData(this.gridOptions.rowData);
    }
  }

  upSum(event) {
    let totalQtde1 = 0;
    let totalVal1 = 0;
    if (event.data.nivel > this.representante) {
      this.gridOptions.rowData?.filter((obj) => obj.nivel === event.data.nivel
        && ((event.node.parent.data.orgHierarchy[0] === obj.orgHierarchy[0]
        && obj.nivel === this.cliente)
        || (event.node.parent.data.orgHierarchy[0] === obj.orgHierarchy[0]
        && event.node.parent.data.orgHierarchy[1] === obj.orgHierarchy[1]
        && obj.nivel === this.linha)))
        .forEach((obj1) => {
          if (event.colDef.field && event.colDef.field === 'metaQuantidade'
            && event.node.orgHierarchyString === obj1.orgHierarchyString) {
            totalQtde1 += Number(event.newValue);
            totalVal1 += Number(obj1.metaValor);
          } else if (event.colDef.field && event.colDef.field === 'metaValor'
            && event.node.orgHierarchyString === obj1.orgHierarchyString) {
            totalQtde1 += Number(obj1.metaQuantidade);
            totalVal1 += Number(event.newValue);
          } else {
            totalQtde1 += Number(obj1.metaQuantidade);
            totalVal1 += Number(obj1.metaValor);
          }
        });
      if (this.gridOptions.rowData && this.gridOptions.rowData.find((obj) => event.node.parent.data.orgHierarchyString
        === obj.orgHierarchyString && obj.tipoCalcMeta === 1)) {
        this.gridOptions.rowData.find((obj) => event.node.parent.data.orgHierarchyString
        === obj.orgHierarchyString && obj.tipoCalcMeta === 1).metaQuantidade = totalQtde1;
        this.gridOptions.rowData.find((obj) => event.node.parent.data.orgHierarchyString
        === obj.orgHierarchyString && obj.tipoCalcMeta === 1).metaValor = totalVal1;
        this.selected.push(this.gridOptions.rowData.find((obj) => event.node.parent.data.orgHierarchyString
        === obj.orgHierarchyString && obj.tipoCalcMeta === 1));
      }
      this.gridApi.setRowData(this.gridOptions.rowData);

      if (event.data.nivel === this.linha) {
        let totalQtde2 = totalQtde1;
        let totalVal2 = totalVal1;
        this.gridOptions.rowData?.filter((obj) => obj.nivel === this.cliente
        && event.node.data.orgHierarchy[0] === obj.orgHierarchy[0]
        && event.node.data.orgHierarchy[1] !== obj.orgHierarchy[1])
          .forEach((obj1) => {
            totalQtde2 += Number(obj1.metaQuantidade);
            totalVal2 += Number(obj1.metaValor);
          });
        if (this.gridOptions.rowData && this.gridOptions.rowData.find((obj) => event.node.parent.parent.data
          .orgHierarchyString === obj.orgHierarchyString && obj.tipoCalcMeta === 1)) {
          this.gridOptions.rowData.find((obj) => event.node.parent.parent.data
            .orgHierarchyString === obj.orgHierarchyString && obj.tipoCalcMeta === 1).metaQuantidade = totalQtde2;
          this.gridOptions.rowData.find((obj) => event.node.parent.parent.data
            .orgHierarchyString === obj.orgHierarchyString && obj.tipoCalcMeta === 1).metaValor = totalVal2;
          this.selected.push(this.gridOptions.rowData.find((obj) => event.node.parent.parent.data
            .orgHierarchyString === obj.orgHierarchyString && obj.tipoCalcMeta === 1));
          this.gridApi.setRowData(this.gridOptions.rowData);
        }
      }
    }
  }

  extractValuesTipos() {
    return Object.keys(Object.fromEntries(this.tiposMetaCalc));
  }

  lookupValueTipos(key) {
    return Object.fromEntries(this.tiposMetaCalc)[key];
  }

  lookupKeyTipos(name) {
    const map = Object.fromEntries(this.tiposMetaCalc);
    map.forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(map, key)) {
        if (name === map[key]) {
          return key;
        }
      }
      return null;
    });
    return null;
  }

  extractValuesPeriodos() {
    return Object.keys(this.metasPeriodos);
  }

  lookupValuePeriodos(key) {
    return this.metasPeriodos[key];
  }

  lookupKeyPeriodos(name) {
    const map = this.metasPeriodos;
    map.forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(map, key)) {
        if (name === map[key]) {
          return key;
        }
      }
      return null;
    });
    return null;
  }

  private filterDataTreePopUp(isClick: boolean) {
    const rem = Array<MetasClienteModel>();
    if (isClick) {
      this.gridApi.setRowData(this.items);
    }
    if (this.gridApi && this.gridApi !== undefined && this.gridOptions
    && this.gridOptions.rowData && this.gridOptions.rowData !== undefined) {
      if (this.filterRepCadAtivo || this.filterRepConAtivo || this.filterCliCadAtivo || this.filterCliConAtivo
      || this.filterProdCadAtivo || this.filterProdConAtivo) {
        this.gridOptions.rowData.forEach((obj) => {
          if (((obj.nivel === this.representante && obj.situacaoCadastro === 0 && this.filterRepCadAtivo)
          || (obj.nivel === this.representante && obj.situacaoContrato === 0 && this.filterRepConAtivo)
          || (obj.nivel === this.cliente && obj.situacaoCadastro === 0 && this.filterCliCadAtivo)
          || (obj.nivel === this.cliente && obj.situacaoContrato === 0 && this.filterCliConAtivo)
          || (obj.nivel === this.linha && obj.situacaoCadastro === 0 && this.filterProdCadAtivo)
          || (obj.nivel === this.linha && obj.situacaoContrato === 0 && this.filterProdConAtivo))
          && !rem.find((obj1) => obj1 === obj)) {
            rem.push(obj);
          }
        });
      }

      if (rem && rem.length > 0) {
        this.gridApi.applyTransaction({ remove: rem });
      }

      if (isClick) {
        this.filterDataTree();
      }
    }
  }

  toggleContrato(event) {
    if (this.gridApi.gridOptionsWrapper.gridOptions.rowData
      .find((obj) => (obj.orgHierarchyString === event.data.orgHierarchyString))) {
      this.gridApi.gridOptionsWrapper.gridOptions.rowData
        .find((obj) => (obj.orgHierarchyString === event.data.orgHierarchyString))
        .situacaoContrato = event.data.situacaoContrato.toString().includes('1') ? '0' : '1';
      this.selected = this.selected.filter((obj) => obj.orgHierarchyString !== event.data.orgHierarchyString);
      this.selected.push(this.gridApi.gridOptionsWrapper.gridOptions.rowData
        .find((obj) => obj.orgHierarchyString === event.data.orgHierarchyString));
    }
  }

  toggleisOpen(open: boolean): void {
    if (open) {
      this.gridOptions.groupDefaultExpanded = 2;
      this.gridApi.expandAll();
      return;
    }
    this.gridOptions.groupDefaultExpanded = 0;
    this.gridApi.collapseAll();
  }

  clearSearch(): void {
    this.filterObjectRepres = null;
    this.filterObjectCli = null;
    this.searchResults = Array<MetasClienteModel>();
    this.searchResultsChildren = Array<MetasClienteModel>();
    this.searchResultsChildrenDistinct = Array<MetasClienteModel>();
    this.filterDataTree();
  }

  async onGridReady(params) {
    this.gridApi = params.api;

    this.tiposMetaCalc = [['0', 'Manual'], ['1', 'Somatório']];

    const res = await this.getMetasPeriodos();
    await Promise.all([res]);

    const columnDefs = [
      { field: 'orgHierarchyString', hide: true },
      { field: 'id', hide: true },
      { field: 'nivel', hide: true },
      { headerName: '', field: 'nome', hide: true },
      { field: 'codRepres', hide: true },
      { field: 'codCli', hide: true },
      { field: 'codLinhaProd', hide: true },
      { field: 'codCli', hide: true },
      { field: 'codLinhaProd', hide: true },
      { field: 'cnpj', hide: true },
      { headerName: 'SITUAÇÃO',
        headerClass: 'header-cell-centered',
        cellClass: 'grid-cell-centered',
        children: [
          { field: 'situacaoCadastro',
            headerName: 'CADASTRO',
            cellClass: 'grid-cell-centered',
            minWidth: 80,
            cellRenderer: (param): string => {
              let ret = '';
              if (param && param.node && param.data && !param.node.rowPinned) {
                ret = `<p>${param.data.situacaoCadastro === 1 ? 'Ativo' : 'Inativo'}</p>`;
              }
              return ret;
            } },
          { field: 'situacaoContrato',
            headerName: 'CONTRATO',
            cellClass: 'grid-cell-centered',
            minWidth: 80,
            cellRenderer: (param) => {
              if (param && param.node && param.node?.rowPinned) {
                return '';
              }
              const input = document.createElement('input');
              input.type = 'checkbox';
              input.checked = param.value;
              input.addEventListener('click', () => {
                this.toggleContrato(param);
              });
              return input;
            } },
        ] },
      { headerName: 'TIPO',
        headerClass: 'header-cell-centered',
        children: [
          { field: 'tipoCalcMeta',
            headerName: '',
            minWidth: 80,
            editable: (param) => {
              if (param && param.data && param.data.situacaoContrato.toString() === '1') {
                return true;
              }
              return false;
            },
            cellClass: (param) => {
              if ((param && param.data && param.data.nivel === this.linha) || param.node?.rowPinned) {
                return 'invisible';
              }
              return 'grid-cell-centered';
            },
            cellEditor: 'select',
            cellEditorParams: {
              values: this.extractValuesTipos(),
            },
            valueFormatter: (param) => this.lookupValueTipos(param.value),
            valueParser: (param) => this.lookupKeyTipos(param.newValue) }] },
      { headerName: 'PERÍODO',
        headerClass: 'header-cell-centered',
        children: [
          { field: 'metaPeriodo',
            headerName: '',
            hide: this.isPercPeriodo(),
            minWidth: 80,
            editable: (param) => {
              if (param && param.data && param.data.situacaoContrato.toString() === '1') {
                return true;
              }
              return false;
            },
            cellClass: (param) => {
              if (param && param.data && param.data.nivel === this.representante && !param.node?.rowPinned) {
                return 'grid-cell-centered';
              }
              return 'invisible';
            },
            cellEditor: 'select',
            cellEditorParams: {
              values: this.extractValuesPeriodos(),
            },
            valueFormatter: (param) => this.lookupValuePeriodos(param.value),
            valueParser: (param) => this.lookupKeyPeriodos(param.newValue) }] },
      { headerName: 'METAS',
        headerClass: 'header-cell-centered',
        cellClass: 'grid-cell-centered',
        children: [{ field: 'metaQuantidade',
          headerName: 'QUANTIDADE',
          hide: this.$route.query.metaQtde === '0',
          cellClass: 'grid-cell-centered',
          minWidth: 80,
          editable: (param) => {
            if ((param && param.data && param.data.situacaoContrato.toString() === '1'
            && param.data.tipoCalcMeta.toString() === '0')
              || param.data.nivel === this.linha) {
              return true;
            }
            return false;
          } },
        { field: 'metaValor',
          headerName: 'VALOR',
          hide: this.$route.query.metaReais === '0',
          cellClass: 'grid-cell-centered',
          minWidth: 80,
          editable: (param) => {
            if ((param && param.data && param.data.situacaoContrato.toString() === '1'
            && param.data.tipoCalcMeta.toString() === '0')
              || (param && param.data && param.data.nivel === this.linha)) {
              return true;
            }
            return false;
          },
          cellRenderer: (param): string => {
            let ret = '';
            if (param && param.data) {
              ret = `<p>${NumberHelper.formatToLocaleDecimal(param.data.metaValor)}</p>`;
            }
            return ret;
          } }] }];

    this.gridApi.setColumnDefs(columnDefs);
    this.getConfigs();
    this.isLoading = false;
  }

  private onRowClicked(e) {
    let represAux = '0';
    let clienteAux = '0';
    if (e.data.nivel === this.representante) {
      represAux = e.data.codRepres.toString();
    } else if (e.data.nivel === this.cliente) {
      represAux = e.node.parent.data.codRepres.toString();
      clienteAux = e.data.codCli.toString();
    }
    this.getChildren(e.data.nivel, e.data.id.toString(),
      represAux,
      e.data.nivel.toString().includes(this.cliente.toString()) ? clienteAux : '');
  }

  private async save() {
    let result;
    let resultLinha;
    try {
      const params = {
        selected: this.selected,
        idContrato: this.$route.query.idContrato,
      };
      if (params.selected.filter((selected) => selected.nivel !== 3).length > 0) {
        result = await this.metasService.saveConfigs(params.selected.filter((selected) => selected.nivel
          !== 3), params.idContrato.toString(), false, false);
        result.forEach((obj1) => {
          if (this.gridOptions.rowData && this.gridOptions.rowData.find((obj2) => obj2.orgHierarchyString
            === obj1.orgHierarchyString)) {
            this.gridOptions.rowData.find((obj2) => obj2.orgHierarchyString
            === obj1.orgHierarchyString).id = obj1.id;
          }
          this.gridApi.setRowData(this.gridOptions.rowData);
        });
      }
      if (params.selected.filter((selected) => selected.nivel === 3).length > 0) {
        resultLinha = await this.metasService.saveConfigsLinha(params.selected.filter((selected) => selected.nivel
          === 3), params.idContrato.toString());
        resultLinha.forEach((obj1) => {
          if (this.gridOptions.rowData && this.gridOptions.rowData.find((obj2) => obj2.orgHierarchyString
            === obj1.orgHierarchyString)) {
            this.gridOptions.rowData.find((obj2) => obj2.orgHierarchyString
            === obj1.orgHierarchyString).id = obj1.id;
          }
          this.gridApi.setRowData(this.gridOptions.rowData);
        });
      }
      this.selected = [];
      this.$notify.success('Dados salvos com sucesso!');
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  filterData(e: Event, nivelHeader): Array<MetasClienteModel> {
    if (e != null && e.toString() !== '') {
      if (nivelHeader.id === this.cliente) {
        const ret = Array<MetasClienteModel>();
        if (this.gridOptions.rowData) {
          this.gridOptions.rowData.forEach((obj) => {
            if (obj.nivel === this.cliente && (obj.nome.toLowerCase().includes(e.toString().toLowerCase())
              || obj.cnpj.includes(e.toString()))) {
              ret.push(obj);
            }
          });
        }
        return ret;
      }
      let ret = Array<MetasClienteModel>();
      if (this.gridOptions.rowData) {
        ret = this.gridOptions.rowData.filter((obj) => obj.nome.toLowerCase()
          .includes(e.toString().toLowerCase())
          || obj.codRepres.toLowerCase().includes(e.toString().toLowerCase()));
      }
      return ret;
    }
    return [];
  }

  filterDataTree(): void {
    const rem = Array<MetasClienteModel>();
    this.gridApi.setRowData(this.items);
    if ((this.nivelHeader.id === this.representante && !this.filterObjectRepres)
    || (this.nivelHeader.id === this.cliente && !this.filterObjectCli)) {
      this.searchResults = Array<MetasClienteModel>();
      this.searchResultsChildren = Array<MetasClienteModel>();
      this.searchResultsChildrenDistinct = Array<MetasClienteModel>();
    }
    if (this.gridOptions.rowData && this.gridOptions.rowData.length > 0) {
      this.gridOptions.rowData.forEach((obj) => {
        if (this.filterObjectRepres && this.nivelHeader.id === this.representante) {
          if (obj.codRepres !== this.filterObjectRepres.codRepres) {
            rem.push(obj);
          }
        } else if (this.filterObjectCli) {
          const repres = this.searchResultsChildren.filter((obj1) => obj1.codCli === this.filterObjectCli.codCli);
          /* remove representantes */
          if (obj.nivel === this.representante && !repres.find((obj2) => obj2.codRepres === obj.codRepres)) {
            rem.push(obj);
          }
        }
      });
      if (rem && rem.length > 0) {
        this.gridApi.applyTransaction({ remove: rem });
      }
      this.filterDataTreePopUp(false);
    }
  }

  handleFileImport() {
    this.isSelecting = true;
    window.addEventListener('focus', () => {
      this.isSelecting = false;
    }, { once: true });
    const element: HTMLElement = document.getElementById('uploader') as HTMLElement;
    if (element instanceof HTMLElement) {
      element.click();
    }
  }

  data() {
    this.gridApi = null;
    this.totalQtde = '0,00';
    this.totalVal = '0,00';
    this.filterObjectRepres = null;
    this.filterObjectCli = null;

    return {
      cliente: this.cliente,
      linha: this.linha,
      loadingComponent: 'AgGridLoading',
      getDataPath(data) {
        return data.orgHierarchy;
      },
      trabalhaPercmetaPeriodo: 1,
      isOpen: false,
      btnChoice: -1,
      items: Array<MetasClienteModel>(),
      selection: [],

      getSize(index: string, nivel: number): string {
        if (nivel === this.cliente && index.includes('nome')) return '21.7em';
        if (nivel === this.linha && index.includes('nome')) return '20.2em';
        if (index.includes('nome')) return '23.2em';
        if (index.includes('situacao')) return '6.7em';
        if (index.includes('tipo')) return '11.5em';
        if (index.includes('metaPeriodo')) return '12.5em';
        if (index.includes('meta')) return '9.6em';
        return '5em';
      },

      getAlign(index: string): string {
        if (index.includes('nome')) return 'left';
        return 'center';
      },

      getClass(index: string): string {
        if (index.includes('nome')) { return 'pt-4 pl-4'; }
        if (index.includes('situacaoContrato')) return 'v-checkbox pt-5 pl-10';
        if (index.includes('situacaoCadastro')) return 'pt-6';
        if (index.includes('tipo') || index.includes('metaPeriodo')) {
          return 'v-select select pr-1 pl-1 text-truncate pt-5';
        }
        if (index.includes('meta')) return 'centered-input pt-2 pr-3 pl-3';
        return '';
      },

      check(e: Event): void {
        e.cancelBubble = true;
      },

      itemsMenu: [
        { id: 1, title: 'Expandir todos os nós' },
        { id: 0, title: 'Recolher todos os nós' },
      ],
    };
  }
}
